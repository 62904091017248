import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AxiosError } from 'axios';

import { getFirebaseError } from './firebase.error';
import { isJSON } from '../functions/functions.utils';
import { translate } from '../lang';

export interface ErrorObject {
  code: string;
  message: string;
}

export function isCodeMessageObject(error: any): error is ErrorObject {
  return isJSON(error) && 'code' in error && 'message' in error;
}

export type ErrorResponse =
  | FetchBaseQueryError
  | SerializedError
  | ErrorObject
  | { error: unknown };

const isFetchBaseQuery = (error: any): error is FetchBaseQueryError => {
  return isJSON(error) && 'status' in error && 'data' in error && !!error.data;
};

export function isOkResponse<T>(data: { error: unknown } | T): data is T {
  return !isErrorResponse(data);
}

function isAxiosFetchError(error: any): error is AxiosError {
  return isJSON(error) && ('response' in error || 'request' in error);
}

const hasNestedErrorObj = (error: any): error is { error: any } => {
  return isJSON(error) && 'error' in error && !!error.error;
};

export const UNKNOWN_CODE = '-100';

export const isResponseValid = <T>(data: T | ErrorResponse): data is T => {
  const response = getErrorResponse(data);
  if (response.code === UNKNOWN_CODE) {
    return true;
  }
  return false;
};

export const isErrorResponse = (data: any): data is ErrorResponse => {
  const IS_SUCCESS_CODE = '-110';
  const response = getErrorResponse(data, undefined, IS_SUCCESS_CODE);

  if (response.code === IS_SUCCESS_CODE) {
    return false;
  }
  return true;
};

export const getErrorResponse = (
  error: any,
  fallbackMessage?: string,
  isSuccessCode?: string,
): ErrorObject => {
  const defaultMessage =
    fallbackMessage || translate('app.feedback.error.unexpectedError');
  if (!error) {
    return { message: defaultMessage, code: isSuccessCode || UNKNOWN_CODE };
  }

  if (!isJSON(error)) {
    return { message: defaultMessage, code: isSuccessCode || UNKNOWN_CODE };
  }

  if (hasNestedErrorObj(error)) {
    return getErrorResponse(error.error, defaultMessage, isSuccessCode);
  }

  if (isAxiosFetchError(error)) {
    if (error.code === 'ERR_NETWORK') {
      return { message: translate('app.network.errorNoInternet'), code: 'ERR_NETWORK' };
    }

    return getErrorResponse(error.response?.data, defaultMessage, isSuccessCode);
  }

  if (isFetchBaseQuery(error)) {
    const data = error.data;
    return getErrorResponse(data, defaultMessage, isSuccessCode);
  }

  if (error instanceof Error) {
    if (error.cause) {
      return getErrorResponse(error.cause, defaultMessage, isSuccessCode);
    }
  }

  if (isCodeMessageObject(error)) {
    // We double check if its firebase error as well
    const codeMessageError = getFirebaseError(error);
    return { code: codeMessageError.code, message: codeMessageError.message };
  }

  return {
    message: defaultMessage,
    code: isSuccessCode || UNKNOWN_CODE,
  };
};

export const handleResponse = <T>(
  data: T | ErrorResponse,
  onSuccess: (_: T) => void | Promise<void>,
  onError?: (_: ErrorObject) => void | Promise<void>,
) => {
  if (isErrorResponse(data)) {
    onError?.(getErrorResponse(data));
    return;
  }
  onSuccess(data);
};
