const YYYYMMDD = /^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/;
//                 yyyy -       MM      -       dd

export function isDateStringInvalid(d: string | undefined) {
  if (!d) return;
  // var re = /^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01]) (0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/;  //24 HR REG TEST
  // //         yyyy -       MM      -       dd              hh       :   mm  :   ss
  if (!YYYYMMDD.test(d) || d.length !== 10) {
    return true;
  }
}

export const CLIENT_DATE_SHORT = 'DD MMM YYYY';
