import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { updateDayjsLocale } from '../date';

export type SupportLanguages = 'en' | 'el' | 'es' | 'fr' | 'nl' | 'de' | 'it';

class LangManager {
  locale: SupportLanguages | undefined;
  initialized = false;

  initiate = async ({
    initialLocale,
    initialResources,
  }: {
    initialLocale: SupportLanguages;
    initialResources?: Resource;
  }) => {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this.locale = initialLocale;

    await i18next
      .use(initReactI18next)
      .init({
        lng: this.locale, // if you're using a language detector, do not define the lng option
        debug: false,
        fallbackLng: 'en',
        compatibilityJSON: 'v3',
        resources: initialResources,
      })
      .catch((error) => {
        console.error(error);
        this.locale = undefined;
        this.initialized = false;
        throw error;
      });
  };

  getSupportLanguages = (): {
    value: SupportLanguages;
    label: string;
    inBuilt: boolean;
  }[] => {
    return [
      { value: 'en', label: 'English', inBuilt: true },
      { value: 'de', label: 'Deutsch', inBuilt: true },
      { value: 'el', label: 'Ελληνικά', inBuilt: true },
      { value: 'es', label: 'Español', inBuilt: true },
      { value: 'fr', label: 'Français', inBuilt: true },
      { value: 'it', label: 'Italiano', inBuilt: true },
      { value: 'nl', label: 'Nederlands', inBuilt: true },
    ];
  };

  loadTranslation = (lang: SupportLanguages, file: any) => {
    i18next.addResourceBundle(lang, 'translation', file);
  };

  changeLanguage = async (locale: SupportLanguages) => {
    this.locale = locale;
    updateDayjsLocale();
    return i18next.changeLanguage(locale);
  };

  getI18NextInstance = () => {
    return i18next;
  };
}

export const i18nManager = new LangManager();
