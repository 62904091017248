import { dayjs } from '../Common/date';

export const DATE_FORMAT_CLIENT = 'DD-MM-YYYY';
export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';

export const DATE_FORMAT_ROUTE_META = 'DD/MM/YY';
export const TIME_FORMAT_ROUTE_META = 'HH:mm';
export const TIME_FORMAT_TICKET_UI = 'h:mm A';

export const getDate = (date: string) => {
  return dayjs(date, DATE_FORMAT_CLIENT, true);
};

/**
 *
 * @param date 'DD-MM-YYYY' DATE_FORMAT_CLIENT
 * @returns boolean
 */
export const isDateValid = (date: string) => {
  return getDate(date).isValid();
};

export const convertToServerDateFormat = (date: string) => {
  return dayjs(date, DATE_FORMAT_SERVER).format(DATE_FORMAT_SERVER);
};
