import { KeyVal } from '@naus-code/naus-client-types';
import groupBy from 'lodash/groupBy';
import { TKeys, translate } from '../lang';

export function groupByFunc<T>(
  array: T[],
  func: (item: T) => string,
): { [key: string]: T[] } {
  return groupBy(array, (item: any) => func(item));
}

export function onlyUnique(
  value: string | number,
  index: number,
  self: (string | number)[],
) {
  return self.indexOf(value) === index;
}

export function mapArrayToKeyValue<T>(
  array: T[],
  func: (item: T, index?: number) => string,
): KeyVal<T>;
export function mapArrayToKeyValue<T, K>(
  array: T[],
  func: (item: T, index?: number) => string,
  mapFunc: (item: T) => K,
): KeyVal<K>;
export function mapArrayToKeyValue<T, K>(
  array: T[],
  func: (item: T, index?: number) => string,
  mapFunc?: (item: T) => K,
): KeyVal<T | K> {
  return array.reduce((cache: KeyVal<T | K>, item, index) => {
    const key = func(item, index);
    cache[key] = mapFunc ? mapFunc(item) : item;
    return cache;
  }, {});
}

export const sortLargestFirst =
  <T>(func: (item: T) => number) =>
  (a: T, b: T) => {
    return func(b) - func(a);
  };

export const sortSmallestFirst =
  <T>(func: (item: T) => number) =>
  (a: T, b: T) => {
    return func(a) - func(b);
  };

export function generateRandomString(length = 10): string {
  let randomString = '';
  const possibleText = 'abcdefghijklmnopqrstuvwxyz1234567890';
  for (let i = 0; i < length; i++) {
    randomString += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
  }
  return randomString;
}

export function getKeysOfObject<T>(obj: T): (keyof T)[] {
  return Object.keys(obj as any) as (keyof T)[];
}

export function existsIn(ids: string | string[] | undefined, id: string): boolean {
  return ids ? ids.indexOf(id) !== -1 : false;
}

export function findIndexFromLast<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function findFromLast<T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean,
): T | undefined {
  const index = findIndexFromLast(array, predicate);
  if (index === -1) return undefined;
  return array[index];
}

export function notEmpty<t>(value: t | null | undefined): value is t {
  return value !== null && value !== undefined;
}

export function mapKeyValueToArray<T>(object: { [key: string]: T | undefined }): T[];
export function mapKeyValueToArray<T, K extends keyof T>(
  object: { [key: string]: T | undefined },
  options: { subKey: K },
): T[K][];
export function mapKeyValueToArray<T, K>(
  object: { [key: string]: T | undefined },
  options: { mapFunc: (item: T) => K },
): K[];
export function mapKeyValueToArray<T, K extends keyof T>(
  object: { [key: string]: T | undefined },
  options?: { subKey?: K; mapFunc?: (item: T) => K },
) {
  let arr = Object.keys(object).map((key) =>
    options?.subKey ? object[key]?.[options?.subKey] : object[key],
  );
  if (options?.mapFunc) {
    arr = arr.map((item) => options?.mapFunc!(item as T) as any);
  }
  return arr.filter(notEmpty) as any;
}

export function isLetter(c: string) {
  return c.toLowerCase() != c.toUpperCase();
}

export const mergeAndUpdateAndAppendNewItems = <T>(
  originalList: T[],
  newList: T[],
  keyExtractor: (_: T) => string,
) => {
  const originalFiltered = originalList.filter((oItem) => {
    return !newList.some((nItem) => {
      return keyExtractor(nItem) === keyExtractor(oItem);
    });
  });

  return [...originalFiltered, ...newList];
};

export const minutesToDuration = (minutes: number, longFormat?: boolean): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const keyPath = longFormat ? 'app.duration.longFormat.' : 'app.duration.shortFormat.';

  const key = (
    hours === 0
      ? keyPath + 'minutes'
      : mins === 0
      ? keyPath + 'hours'
      : keyPath + 'hoursAndMinutes'
  ) as TKeys;

  return translate(key, { hours, minutes: mins });
};

export function isJSON(error: any): error is object {
  return error !== null && typeof error === 'object' && !Array.isArray(error);
}

export const createPause = (delay: number) => {
  return new Promise((res) => {
    setTimeout(() => {
      res(null);
    }, delay);
  });
};

export const mergeAndRemoveDuplicates = <T>(
  originalList: T[],
  newList: T[],
  key: keyof T,
) => {
  const toDeleteItems = [] as number[];
  const mappedList = originalList.map(
    (oItem) =>
      newList.find((nItem, ix) => {
        const isMatch = nItem[key] === oItem[key];
        if (isMatch) {
          toDeleteItems.push(ix);
        }
        return isMatch;
      }) || oItem,
  );

  const freshNewList = newList.filter((_, iy) => !toDeleteItems.some((v) => v === iy));
  return [...mappedList, ...freshNewList];
};

export const onlyUniqueByFunc: <T>(
  func: (item: T) => string,
) => (value: T, index: number, self: T[]) => boolean = (func) => (value, index, self) => {
  return self.findIndex((item) => func(item) === func(value)) === index;
};

export function firstNonEmptyOrLast<T>(...args: T[]): T | undefined {
  const result = args.find((a) => !!a);
  if (!result) {
    return args[args.length - 1];
  }
}

function compareLocale(a: string, b: string) {
  return a.localeCompare(b, undefined);
}

export const groupListAlphabetically = (
  list: {
    label: string;
    [key: string]: any;
  }[],
) => {
  const groupedByKey = groupByFunc(list, (item) =>
    item.label.slice(0, 1).toLocaleUpperCase(),
  );
  const letterSorted = Object.keys(groupedByKey).sort(compareLocale);
  return letterSorted.map((letter) => ({
    title: letter.toLocaleUpperCase(),
    data: groupedByKey[letter],
  }));
};
